import React from 'react'
import { Button } from 'react-bulma-components'
import me from './me.png'

function Appbar() {
    return (
        <div>
            <div className="hero is-primary is-fullheight">
                <div className="hero-body">
                    <div className="container">
                        <table>
                            <tr className="level"> 
                                <td><img src={me} alt="sd" /></td>
                                <td>
                                <h1 className="title is-1">Hi I am Souvik!</h1>
                                <h2 className="subtitle">I am budding developer, looking for new oportunities.</h2>
                                </td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Appbar 