import React from 'react'
import Appbar from './components/Appbar/Appbar'
import Aboutme from './components/Aboutme/Aboutme'

class App extends React.Component{
    render(){
        return(
            <div>
                <Appbar/>
                <Aboutme/>
            </div>
        )
    }
}


export default App