import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './App.sass';


ReactDOM.render(
    <div>
        <App></App>
    </div>
,document.getElementById("root"));