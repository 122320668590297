import React from 'react'
import twitter from './twitter.png'
import linkedin from './linkedin.png'
import github from './github.png'
import gmail from './gmail.png'

function Aboutme() {
    return (
        <div>
            <div className="box">
                <div className="hero">
                    <div className="level">
                        <div className="level-item">
                            <h1 className="title">Contact Me</h1>
                        </div>

                    </div>

                    <div className="section is-medium">
                    <div className="level">
                        <div className="level-item">
                            <figure className="image is-32x32">
                                <a href="https://twitter.com/souvik_ns">
                                    <img src={twitter} alt="twitter" />
                                </a>
                            </figure>
                        </div>
                        <div className="level-item">
                            <figure className="image is-32x32">
                                <a href="https://www.linkedin.com/in/souvik-de-a2b941169/">
                                    <img src={linkedin} alt="" />
                                </a>
                            </figure>

                        </div>
                        <div className="level-item">
                            <figure className="image is-32x32">
                                <a href="https://github.com/Souvikns"><img src={github} alt="" />
                                </a>
                            </figure>

                        </div>
                        <div className="level-item">
                            <figure className="image is-32x32">
                                <a href="mailto:souvikde.ns@gmail.com"><img src={gmail} alt="" />
                                </a>
                            </figure>

                        </div>

                    </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Aboutme